import { reactive, ref } from 'vue'
import { CLAIM_TYPE, CLAIM_IMAGE_CATEGORY, CLAIM_STATUS } from './type'
import { getClaimListAPI, updateClaimStatus } from '@/api/claim/claim.api'
import { ElNotification } from 'element-plus'
import { useCourierStore } from '@/pinia/courier/courier.pinia'

type ClaimTypeOptions = {
	value: string
	label: string
}

type UploadParams = {
	category: CLAIM_IMAGE_CATEGORY
	images: File[] | string[] | any
	shopId: string
	orderId: string
}

const constantClaimTypeOption = [
	{
		label: 'พัสดุเสียหาย',
		value: CLAIM_TYPE.DAMAGED_BOX
	},
	{
		label: 'สินค้าภายในได้รับความเสียหาย',
		value: CLAIM_TYPE.DAMAGED_PRODUCT
	},
	{
		label: 'พัสดุสูญหาย',
		value: CLAIM_TYPE.MISSING_PRODUCT
	},
	{
		label: 'ได้รับสินค้าผิด',
		value: CLAIM_TYPE.WRONG_PRODUCT
	},
]

const constantClaimStatus = [
	{
		label: 'เคลมสำเร็จ',
		value: CLAIM_STATUS.APPROVED
	},
	{
		label: 'ระหว่างตรวจสอบเอกสาร',
		value: CLAIM_STATUS.PENDING
	},
	{
		label: 'ระหว่างดำเนินการ',
		value: CLAIM_STATUS.IN_PROGRESS
	},
	{
		label: 'ยื่นเอกสารเพิ่มเติม',
		value: CLAIM_STATUS.ADDITIONAL
	},
	{
		label: 'ถูกปฏิเสธ',
		value: CLAIM_STATUS.REJECTED
	}
]

export const useClaim = () => {
	const searchQuery = reactive({
		limit: 10,
		offset: 1,
		totals: 0,
		type: null,
		status: null,
		search: null as any,
		courier: null,
	})
	const isFetching = ref(false)
	const claimList = ref<any[]>([])
	const claimStatusSelect = ref<null | CLAIM_STATUS>(null)
	const updateClaimStatusSelected = ref<CLAIM_STATUS>(CLAIM_STATUS.APPROVED)
	const selectedUpdateOrderId = ref<string>()
	const isShowDialogUpdateStatus = ref(false)
	const rejectReason = ref('')
	const courierStore = useCourierStore()
	const totalClaim = ref('')

	const getClaimList = async () => {
		//
		isFetching.value = true
		try {
			const payload: any = {
				type: searchQuery.type,
				offset: searchQuery.offset,
				limit: searchQuery.limit,
				trackingNumber: searchQuery.search,
			}
		
			if (searchQuery.courier !== 'all') {
				payload.courier = searchQuery.courier
			}
			
			if (searchQuery.status !== 'all') {
				payload.status = searchQuery.status
			}

			const { data: response } = await getClaimListAPI(payload)
			
			claimList.value = courierStore.mapCourierWithOrder({ data: response.data }).data

			searchQuery.totals = response.pagination?.totals
		} catch (error) {
			//
		} finally {
			isFetching.value = false
		}
	}

	const onUpdateClaim = (row: any) => {
		//
		selectedUpdateOrderId.value = row.orderId
		rejectReason.value = row.rejectedReason
		updateClaimStatusSelected.value = row.status
		isShowDialogUpdateStatus.value = true
		totalClaim.value = row.totalClaim && row.totalClaim > 0 ?  row.totalClaim || 0 : row.cost
	}

	const onConfirmClaim = async () => {
		//
		const payload: any = {
			orderId: selectedUpdateOrderId.value as string,
			status: updateClaimStatusSelected.value,
			rejectedReason: rejectReason.value,
		}
		if(updateClaimStatusSelected.value == CLAIM_STATUS.APPROVED){
			payload.totalClaim = parseFloat(totalClaim.value)
		}

		await updateClaimStatus(payload)

		ElNotification({
			title: 'สำเร็จ',
			message: 'อัพเดทสถานะสำเร็จ',
			type: 'success'
		})

		getClaimList()

		isShowDialogUpdateStatus.value = false
	}

	return {
		isFetching,
		claimList,
		constantClaimStatus,
		constantClaimTypeOption,
		searchQuery,
		getClaimList,
		claimStatusSelect,
		onUpdateClaim,
		isShowDialogUpdateStatus,
		rejectReason,
		onConfirmClaim,
		updateClaimStatusSelected,
		totalClaim,
	}
}