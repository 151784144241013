
export enum CLAIM_STATUS {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ADDITIONAL = 'ADDITIONAL',
  }
  
export enum CLAIM_TYPE {
    DAMAGED_PRODUCT = 'DAMAGED_PRODUCT',
    WRONG_PRODUCT = 'WRONG_PRODUCT',
    MISSING_PRODUCT = 'MISSING_PRODUCT',
    DAMAGED_BOX = 'DAMAGED_BOX',
  }
  
export enum CLAIM_IMAGE_CATEGORY {
    PRODUCT_IMAGE = 'PRODUCT_IMAGE',
    PRODUCT_LABEL = 'PRODUCT_LABEL',
    TRANSACTION_IMAGE = 'TRANSACTION_IMAGE',
    DAMAGED_PRODUCT = 'DAMAGED_PRODUCT',
    MISSING_PRODUCT = 'MISSING_PRODUCT',
    WRONG_PRODUCT = 'WRONG_PRODUCT',
    OTHER = 'OTHER',
  }
  